import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts'
import LessonCard from '../components/Lesson/LessonCard'
import Seo from '../components/Seo'
import Footer from '../components/Footer';

const libraryTemplate = props => {
  const { title, description, image, slug, mediaLibraryButtonText, mediaLibraryLink, videoLibraryButtonText, videoLibraryLink } = props.data.librarys
  const cards = props.data.librarys.libraryCards

  const seo = {
    title,
    slug,
    description,
    image,
  }
  return <Layout currentSlug={'/' + slug}>
      <Seo {...seo} />
      <div className="l-section">
        {/* TODO Move to lesson component */}
        <LessonCard title={title} image={image} isHeaderCard={true} />
        <ul className="b-lessons__list h-list-unstyled">
          {cards && cards.map(card => (
            <li
              key={card.id}
              className="b-lessons__list-item b-lessons__list-item"
            >
              <LessonCard {...card} library={true} />
            </li>
          ))}
        </ul>
        <div className="b-lessons__buttons">
          {videoLibraryLink && <Link className="b-lesson__button b-button b-button--quiz" to={`/${videoLibraryLink.slug}`}>
            {videoLibraryButtonText}
          </Link>}
          {mediaLibraryLink && <Link className="b-lesson__button b-button b-button--quiz" to={`/${mediaLibraryLink.slug}`}>
            {mediaLibraryButtonText}
          </Link>}
        </div>
      </div>
      <Footer />
    </Layout>
}

export default libraryTemplate

export const pageQuery = graphql`
  query libraryQuery($id: String!) {
    librarys: contentfulLibrary(id: { eq: $id }) {
      title
      description
      slug
      libraryCards {
        id
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          id
          file {
            url
            fileName
            contentType
          }
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
            srcWebp
            srcSetWebp
          }
        }
        video
        fileToDownload {
          file {
            url
            fileName
            contentType
          }
        }
      }
      image {
        id
        file {
          url
          fileName
          contentType
        }
        fluid {
          aspectRatio
          base64
          src
          sizes
          srcSet
          srcWebp
          srcSetWebp
        }
      }
      mediaLibraryButtonText
      mediaLibraryLink{
        slug
      } 
      videoLibraryButtonText
      videoLibraryLink {
        slug
      }
    }
  }
`
